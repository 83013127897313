import { getField, updateField } from 'vuex-map-fields'
export const initState = {
  activeIdx: null,
  nav: []
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  init ({ commit }, value) {
    commit('init', value)
    if (this.$config.appDebug) {
      commit('addDebug')
    }
  },
  setActiveIdx ({ commit }, idx) {
    commit('setActiveIdx', idx)
  },
  setActivePath ({ commit }, path) {
    commit('setActivePath', path)
  }
}
export const mutations = {
  updateField,
  init (state, value) {
    state.nav = []
    if (value && value.length) {
      value.forEach((item, idx) => {
        item.idx = `${idx}`
        if (item.childs && item.childs.length) {
          item.childs.map((subItem, subIdx) => {
            subItem.idx = `${item.idx}.${subIdx}`
            return subItem
          })
        }
        state.nav.push(item)
      })
    }
  },
  setActiveIdx (state, idx) {
    this.activeIdx = idx
  },
  setActivePath (state, path) {
    state.nav.some((item, idx) => {
      if (item.path === path) {
        state.activeIdx = item.idx
        return true
      }
      if (item.childs && item.childs.length) {
        return item.childs.some((subItem, subIdx) => {
          if (subItem.path === path) {
            state.activeIdx = subItem.idx
            return true
          }
          return false
        })
      }
      return false
    })
  },
  addDebug (state) {
    state.nav.push({
      title: 'debug',
      path: '/debug',
      icon: 'bug',
      roles: 'admin|editor',
      isEnabled: true
    })
  }
}
export const getters = {
  getField,
  activeIdx (state) {
    return state.activeIdx
  },
  nav (state) {
    return state.nav || []
  },
  isActiveFn: state => (idx) => {
    if (state.activeIdx === idx) {
      return true
    } else if (state.activeIdx) {
      const keys = state.activeIdx.split('.')
      if (keys.length > 1 && keys[0] === idx) {
        return true
      }
    }
    return false
  }
}
