import { getField, updateField } from 'vuex-map-fields'
// import $axios from 'axios'
import { uri, prepareUrl } from '~/api/uri'
export const initState = {}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async approve (state, { userId, id }) {
    await this.$axios.post(prepareUrl(uri.customers.paymentApprove, { userId, id }))
  }
}
export const mutations = {
  updateField
}
export const getters = {
  getField
}
