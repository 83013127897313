import { getField, updateField } from 'vuex-map-fields'
import { uri, prepareUrl } from '~/api/uri'
export const initState = {}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async transform (state, { id, action }) {
    await this.$axios.post(prepareUrl(uri.media.transform, { id, action }))
  },
  async add (state, { id, file }) {
    const fd = new FormData()
    fd.append('file', file)
    await this.$axios.post(prepareUrl(uri.media.add, { id }), fd)
  },
  async update (state, { id, data }) {
    await this.$axios.post(prepareUrl(uri.media.update, { id }), data)
  },
  async delete (state, { id, data }) {
    await this.$axios.delete(prepareUrl(uri.media.delete, { id }), data)
  },
  async validate (state, { id, data, onSuccess, onError }) {
    const res = await this.$axios.post(prepareUrl(uri.media.validate, { id }), data)
    if (typeof res?.data?.result !== 'undefined') {
      if (!!res.data.result && typeof onSuccess === 'function') {
        onSuccess()
      } else if (!res.data.result && typeof onError === 'function') {
        onError()
      }
    }
  },
  async validateAll (state, { id }) {
    await this.$axios.post(prepareUrl(uri.media.validateAll, { id }))
  },
  async addAvatar (state, { mediaId, file }) {
    const fd = new FormData()
    fd.append('file', file)
    await this.$axios.post(prepareUrl(uri.media.addAvatar, { id: mediaId }), fd)
  }
}
export const mutations = {
  updateField
}
export const getters = {
  getField
}
