import { getField, updateField } from 'vuex-map-fields'
import * as moment from 'moment'
import { uri } from '~/api/uri'
export const initState = {
  page: 1,
  pageSize: 200,
  filter: {
    from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    to: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
    interval: '1m',
    user_type: 'all',
    affiliate: '',
    source: '',
    campaign: '',
    medium: '',
    market: '',
    referer: ''
  },
  filterData: {
    interval: [{ value: '', text: '---' }],
    user_type: [{ value: 'all', text: '---' }],
    affiliate: [{ value: '', text: '---' }],
    source: [{ value: '', text: '---' }],
    campaign: [{ value: '', text: '---' }],
    medium: [{ value: '', text: '---' }],
    market: [{ value: '', text: '---' }]
  },
  reportData: {
    bg: null,
    border: null,
    data: null,
    dates: null,
    items: null,
    items_expenses: null,
    labels: null,
    totals: null,
    unlocked: null,
    user_type_totals: null
  },
  reportCsv: null,
  reportXls: null,
  fetchedVersion: null
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async fetchFilterData ({ commit }) {
    const filterData = await this.$axios.$get(uri.reports.users.filterData)
    commit('setFilterData', filterData)
  },
  async fetchReport ({ commit, state }) {
    try {
      this.$loadingManual().start()
      const itemsUrl = `${uri.reports.users.reportItemsData}?page=${state.page}&limit=${state.pageSize}`
      const chartUrlrl = `${uri.reports.users.reportChartData}`
      const itemsData = await this.$axios.$post(itemsUrl, {
        from: state.filter.from,
        to: state.filter.to,
        interval: state.filter.interval,
        user_type: state.filter.user_type,
        affiliate: state.filter.affiliate,
        source: state.filter.source,
        campaign: state.filter.campaign,
        medium: state.filter.medium,
        market: state.filter.market,
        referer: state.filter.referer
      })
      const chartData = await this.$axios.$post(chartUrlrl, {
        from: state.filter.from,
        to: state.filter.to,
        interval: state.filter.interval,
        user_type: state.filter.user_type,
        affiliate: state.filter.affiliate,
        source: state.filter.source,
        campaign: state.filter.campaign,
        medium: state.filter.medium,
        market: state.filter.market,
        referer: state.filter.referer
      })
      commit('setReportItemsData', itemsData)
      commit('setReportChartData', chartData)
    } catch (e) {
      // do some
    } finally {
      commit('updateFetchedVersion')
      this.$loadingManual().finish()
    }
  },
  async fetchReportChartData ({ commit, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.users.reportChartData}`
      const reportData = await this.$axios.$post(url, {
        from: state.filter.from,
        to: state.filter.to,
        interval: state.filter.interval,
        user_type: state.filter.user_type,
        affiliate: state.filter.affiliate,
        source: state.filter.source,
        campaign: state.filter.campaign,
        medium: state.filter.medium,
        market: state.filter.market,
        referer: state.filter.referer
      })
      commit('setReportChartData', reportData)
    } catch (e) {
      // do some
      // console.log('fetchReportChartData Error', e)
    } finally {
      this.$loadingManual().finish()
    }
  },
  fetchReportItemsData ({ commit, state }) {
    this.$loadingManual().start()
    const url = `${uri.reports.users.reportItemsData}?page=${state.page}&limit=${state.pageSize}`
    this.$axios.$post(url, {
      from: state.filter.from,
      to: state.filter.to,
      interval: state.filter.interval,
      user_type: state.filter.user_type,
      affiliate: state.filter.affiliate,
      source: state.filter.source,
      campaign: state.filter.campaign,
      medium: state.filter.medium,
      market: state.filter.market,
      referer: state.filter.referer
    })
      .then((reportData) => {
        commit('setReportItemsData', reportData)
      })
      .finally(() => {
        this.$loadingManual().finish()
      })
  },
  async fetchReportCsv ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.users.reportScv}`
      const responce = await this.$axios.$post(url, {
        from: state.filter.from,
        to: state.filter.to,
        interval: state.filter.interval,
        user_type: state.filter.user_type,
        affiliate: state.filter.affiliate,
        source: state.filter.source,
        campaign: state.filter.campaign,
        medium: state.filter.medium,
        market: state.filter.market,
        referer: state.filter.referer
      })
      commit('setReportCsv', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async fetchReportXls ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.users.reportXls}`
      const responce = await this.$axios.$post(url, {
        from: state.filter.from,
        to: state.filter.to,
        interval: state.filter.interval,
        user_type: state.filter.user_type,
        affiliate: state.filter.affiliate,
        source: state.filter.source,
        campaign: state.filter.campaign,
        medium: state.filter.medium,
        market: state.filter.market,
        referer: state.filter.referer
      }, {
        responseType: 'blob'
      })
      commit('setReportXls', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async resetFilter ({ commit, dispatch }) {
    commit('resetFilter')
    await dispatch('fetchReportData')
  }
}
export const mutations = {
  updateField,
  setFilterData (state, value) {
    state.filterData = value
  },
  setReportChartData (state, value) {
    state.reportData.bg = value.bg
    state.reportData.border = value.border
    state.reportData.data = value.data
    state.reportData.dates = value.dates
    state.reportData.items_expenses = value.items_expenses
    state.reportData.labels = value.labels
    state.reportData.totals = value.totals
    state.reportData.unlocked = value.unlocked
    state.reportData.user_type_totals = value.user_type_totals
  },
  setReportItemsData (state, value) {
    state.reportData.items = value.items
    state.page = value.items?.current_page || 1
  },
  setReportCsv (state, value) {
    state.reportCsv = value
  },
  setReportXls (state, value) {
    state.reportXls = value
  },
  resetFilter (state) {
    state.filter.from = moment().subtract(30, 'days').format('YYYY-MM-DD')
    state.filter.to = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
    state.filter.interval = '1m'
    state.filter.user_type = 'all'
    state.filter.affiliate = ''
    state.filter.source = ''
    state.filter.campaign = ''
    state.filter.medium = ''
    state.filter.market = ''
    state.filter.referer = ''
  },
  updateFetchedVersion (state) {
    state.fetchedVersion = JSON.stringify(state.filter).hashCode()
  }
}
export const getters = {
  getField,
  filter (state) {
    return state.filter
  },
  actualVersion (state) {
    return JSON.stringify(state.filter).hashCode()
  },
  isFetched (state, getters) {
    return state.fetchedVersion === getters.actualVersion
  }
}
