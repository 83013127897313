import { getField, updateField } from 'vuex-map-fields'
import * as moment from 'moment'
import { uri } from '~/api/uri'
export const initState = {
  page: 1,
  pageSize: 200,
  filter: {
    start_date: moment().format('YYYY-MM-DD'),
    finish_date: moment().format('YYYY-MM-DD'),
    user_type: '',
    status: '',
    platform: '',
    club_member: ''
  },
  filterData: {
    user_type: [{ value: '', text: '---' }],
    status: [{ value: '', text: '---' }],
    platform: [{ value: '', text: '---' }],
    club_member: [{ value: '', text: '---' }]
  },
  reportData: {},
  reportPager: null,
  reportTotals: '',
  reportCsv: null,
  reportXls: null
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async fetchFilterData ({ commit }) {
    const filterData = await this.$axios.$get(uri.reports.payments.filterData)
    commit('setFilterData', filterData)
  },
  async fetchReportData ({ commit, state }) {
    try {
      if (this.$loadingManual().state()) {
        return
      }
      this.$loadingManual().start()
      const url = `${uri.reports.payments.reportData}?page=${state.page}&limit=${state.pageSize}`
      const responce = await this.$axios.$post(url, {
        filter: {
          start_date: state.filter.start_date,
          finish_date: state.filter.finish_date,
          user_type: state.filter.user_type,
          status: state.filter.status,
          platform: state.filter.platform,
          club_member: state.filter.club_member
        }
      })
      commit('setReportData', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async fetchReportCsv ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.payments.reportScv}`
      const responce = await this.$axios.$post(url, {
        filter: {
          start_date: state.filter.start_date,
          finish_date: state.filter.finish_date,
          user_type: state.filter.user_type,
          status: state.filter.status,
          platform: state.filter.platform,
          club_member: state.filter.club_member
        }
      })
      commit('setReportCsv', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async fetchReportXls ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.payments.reportXls}`
      const responce = await this.$axios.$post(url, {
        filter: {
          start_date: state.filter.start_date,
          finish_date: state.filter.finish_date,
          user_type: state.filter.user_type,
          status: state.filter.status,
          platform: state.filter.platform,
          club_member: state.filter.club_member
        }
      }, {
        responseType: 'blob'
      })
      commit('setReportXls', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async resetFilter ({ commit, dispatch }) {
    commit('resetFilter')
    await dispatch('fetchReportData')
  }
}
export const mutations = {
  updateField,
  setFilterData (state, value) {
    state.filterData = value
  },
  setReportData (state, value) {
    state.reportData = value?.data
    state.reportTotals = value?.meta.total
    state.reportPager = value?.meta.pagination
  },
  setReportCsv (state, value) {
    state.reportCsv = value
  },
  setReportXls (state, value) {
    state.reportXls = value
  },
  resetFilter (state) {
    state.filter.start_date = moment().startOf('year').format('YYYY-MM-DD')
    state.filter.finish_date = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
    state.filter.user_type = ''
    state.filter.status = ''
    state.filter.platform = ''
    state.filter.club_member = ''
  }
}
export const getters = {
  getField
}
