import { getField, updateField } from 'vuex-map-fields'
import * as moment from 'moment'
import { uri } from '~/api/uri'
export const initState = {
  filter: {
    from: moment().subtract(30, 'days').startOf('month').format('YYYY-MM-DD'),
    to: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
    interval: '1m',
    user_type: 'all',
    affiliate: '',
    source: '',
    campaign: '',
    medium: '',
    market: '',
    referer: ''
  },
  filterData: {
    interval: [{ value: '', text: '---' }],
    user_type: [{ value: 'all', text: '---' }],
    affiliate: [{ value: '', text: '---' }],
    source: [{ value: '', text: '---' }],
    campaign: [{ value: '', text: '---' }],
    medium: [{ value: '', text: '---' }],
    market: [{ value: '', text: '---' }]
  },
  reportDates: {},
  reportData: {},
  reportTotals: '',
  reportCsv: null,
  reportXls: null
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async fetchFilterData ({ commit }) {
    const filterData = await this.$axios.$get(uri.reports.cities.filterData)
    commit('setFilterData', filterData)
  },
  async fetchReportData ({ commit, state }) {
    try {
      this.$loadingManual().start()
      const responce = await this.$axios.$post(uri.reports.cities.reportData, {
        from: state.filter.from,
        to: state.filter.to,
        interval: state.filter.interval,
        user_type: state.filter.user_type,
        affiliate: state.filter.affiliate,
        source: state.filter.source,
        campaign: state.filter.campaign,
        medium: state.filter.medium,
        market: state.filter.market,
        referer: state.filter.referer
      })
      commit('setReportData', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async fetchReportCsv ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.cities.reportScv}`
      const responce = await this.$axios.$post(url, {
        from: state.filter.from,
        to: state.filter.to,
        interval: state.filter.interval,
        user_type: state.filter.user_type,
        affiliate: state.filter.affiliate,
        source: state.filter.source,
        campaign: state.filter.campaign,
        medium: state.filter.medium,
        market: state.filter.market,
        referer: state.filter.referer
      })
      commit('setReportCsv', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async fetchReportXls ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.cities.reportXls}`
      const responce = await this.$axios.$post(url, {
        from: state.filter.from,
        to: state.filter.to,
        interval: state.filter.interval,
        user_type: state.filter.user_type,
        affiliate: state.filter.affiliate,
        source: state.filter.source,
        campaign: state.filter.campaign,
        medium: state.filter.medium,
        market: state.filter.market,
        referer: state.filter.referer
      }, {
        responseType: 'blob'
      })
      commit('setReportXls', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async resetFilter ({ commit, dispatch }) {
    commit('resetFilter')
    await dispatch('fetchReportData')
  }
}
export const mutations = {
  updateField,
  setFilterData (state, value) {
    state.filterData = value
  },
  setReportData (state, value) {
    state.reportData = value?.items
    state.reportDates = value?.dates
    state.reportTotals = value?.totals
  },
  setReportCsv (state, value) {
    state.reportCsv = value
  },
  setReportXls (state, value) {
    state.reportXls = value
  },
  resetFilter (state) {
    state.filter.from = moment().startOf('year').format('YYYY-MM-DD')
    state.filter.to = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
    state.filter.interval = '1m'
    state.filter.user_type = 'all'
    state.filter.affiliate = ''
    state.filter.source = ''
    state.filter.campaign = ''
    state.filter.medium = ''
    state.filter.market = ''
    state.filter.referer = ''
  }
}
export const getters = {
  getField
}
