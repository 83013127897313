import { getField, updateField } from 'vuex-map-fields'
import * as moment from 'moment'
import { uri } from '~/api/uri'
export const initState = {
  filter: {
    market: null,
    platform: 'hostesswing',
    startDate: moment().subtract(30, 'days').startOf('month').format('YYYY-MM-DD'),
    stopDate: moment().startOf('day').format('YYYY-MM-DD')
  },
  filterData: {
    market: [{ value: '', text: '---' }],
    platform: [{ value: '', text: '---' }]
  },
  reportData: {},
  reportTotals: {},
  reportCsv: null,
  reportXls: null
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async fetchFilterData ({ commit }) {
    const filterData = await this.$axios.$get(uri.reports.hostess.filterData)
    commit('setFilterData', filterData)
  },
  async fetchReportData ({ commit, state }) {
    try {
      this.$loadingManual().start()
      const responce = await this.$axios.$post(uri.reports.hostess.reportData, {
        filter: {
          market: state.filter.market,
          platform: state.filter.platform,
          startDate: state.filter.startDate,
          stopDate: state.filter.stopDate
        }
      })
      commit('setReportData', responce?.items)
      commit('setReportTotals', responce?.totals)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async fetchReportCsv ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.hostess.reportScv}`
      const responce = await this.$axios.$post(url, {
        filter: {
          market: state.filter.market,
          platform: state.filter.platform,
          startDate: state.filter.startDate,
          stopDate: state.filter.stopDate
        }
      })
      commit('setReportCsv', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async fetchReportXls ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.hostess.reportXls}`
      const responce = await this.$axios.$post(url, {
        filter: {
          market: state.filter.market,
          platform: state.filter.platform,
          startDate: state.filter.startDate,
          stopDate: state.filter.stopDate
        }
      }, {
        responseType: 'blob'
      })
      commit('setReportXls', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async resetFilter ({ commit, dispatch }) {
    commit('resetFilter')
    await dispatch('fetchReportData')
  }
}
export const mutations = {
  updateField,
  setFilterData (state, value) {
    state.filterData = value
  },
  setReportData (state, value) {
    state.reportData = value
  },
  setReportTotals (state, value) {
    state.reportTotals = value
  },
  setReportCsv (state, value) {
    state.reportCsv = value
  },
  setReportXls (state, value) {
    state.reportXls = value
  },
  resetFilter (state) {
    state.filter.market = 'IT'
    state.filter.platform = 'hostesswing'
    state.filter.startDate = moment().startOf('year').format('YYYY-MM-DD')
    state.filter.stopDate = moment().startOf('day').format('YYYY-MM-DD')
  }
}
export const getters = {
  getField
}
