import { getField, updateField } from 'vuex-map-fields'
export const initState = {
  windowIcon: 'favicon',
  windowTitle: '',
  pageTitle: '',
  pageIcon: '',
  sidebarState: false
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  setPageTitle ({ commit }, value) {
    commit('setPageTitle', value)
  },
  setPageIcon ({ commit }, value) {
    commit('setPageIcon', value)
  },
  setWindowIcon ({ commit }, value) {
    commit('setWindowIcon', value)
  },
  setWindowTitle ({ commit }, value) {
    commit('setWindowTitle', value)
  }
}
export const mutations = {
  updateField,
  setPageTitle (state, value) {
    state.pageTitle = value
  },
  setPageIcon (state, value) {
    state.pageIcon = value
  },
  setWindowIcon (state, value) {
    state.windowIcon = value
  },
  setWindowTitle (state, value) {
    state.windowTitle = value
  }
}
export const getters = {
  getField,
  pageTitle (state, getters, rootState) {
    return `${state.pageTitle}`
  },
  pageIcon (state) {
    return state.pageIcon
  },
  sidebarState (state) {
    return state.sidebarState
  }
}
