import { getField, updateField } from 'vuex-map-fields'
import * as moment from 'moment'
import { uri } from '~/api/uri'
export const initState = {
  page: 1,
  pageSize: 200,
  filter: {
    market: '',
    platform: '',
    interval: '1m',
    startDate: moment().subtract(30, 'days').startOf('month').format('YYYY-MM-DD'),
    stopDate: moment().startOf('day').format('YYYY-MM-DD')
  },
  filterData: {
    market: [{ value: '', text: '---' }],
    platform: [{ value: '', text: '---' }],
    interval: [{ value: '', text: '---' }]
  },
  dates: [],
  reportData: {},
  reportTotals: {},
  reportCsv: null,
  reportXls: null
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async fetchFilterData ({ commit }) {
    const filterData = await this.$axios.$get(uri.reports.hostessChat.filterData)
    commit('setFilterData', filterData)
  },
  async fetchReportData ({ commit, state }) {
    try {
      if (this.$loadingManual().state()) {
        return
      }
      this.$loadingManual().start()
      const url = `${uri.reports.hostessChat.reportData}?page=${state.page}&limit=${state.pageSize}`
      const responce = await this.$axios.$post(url, {
        market: state.filter.market,
        platform: state.filter.platform,
        interval: state.filter.interval,
        startDate: state.filter.startDate,
        stopDate: state.filter.stopDate
      })
      commit('setDates', responce?.dates)
      commit('setReportData', responce?.items)
      commit('setReportTotals', responce?.totals)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async fetchReportCsv ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.hostessChat.reportScv}`
      const responce = await this.$axios.$post(url, {
        market: state.filter.market,
        platform: state.filter.platform,
        interval: state.filter.interval,
        startDate: state.filter.startDate,
        stopDate: state.filter.stopDate
      })
      commit('setReportCsv', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async fetchReportXls ({ commit, dispatch, state }) {
    try {
      this.$loadingManual().start()
      const url = `${uri.reports.hostessChat.reportXls}`
      const responce = await this.$axios.$post(url, {
        market: state.filter.market,
        platform: state.filter.platform,
        interval: state.filter.interval,
        startDate: state.filter.startDate,
        stopDate: state.filter.stopDate
      }, {
        responseType: 'blob'
      })
      commit('setReportXls', responce)
    } catch (e) {
      // do some
    } finally {
      this.$loadingManual().finish()
    }
  },
  async resetFilter ({ commit, dispatch }) {
    commit('resetFilter')
    await dispatch('fetchReportData')
  }
}
export const mutations = {
  updateField,
  setDates (state, value) {
    state.dates = value
  },
  setFilterData (state, value) {
    state.filterData = value
  },
  setReportData (state, value) {
    state.reportData = value
    state.page = value?.current_page || 1
  },
  setReportTotals (state, value) {
    state.reportTotals = value
  },
  setReportCsv (state, value) {
    state.reportCsv = value
  },
  setReportXls (state, value) {
    state.reportXls = value
  },
  resetFilter (state) {
    state.filter.market = ''
    state.filter.platform = ''
    state.filter.startDate = moment().startOf('year').format('YYYY-MM-DD')
    state.filter.stopDate = moment().startOf('day').format('YYYY-MM-DD')
  }
}
export const getters = {
  getField
}
